<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[6].Quotes[0].Quotes }}
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[6].Quotes[0].Quotes_to_respond_to }}
                  </h4>
                  <span>Total: <strong>( {{ quotesNew.length }} )</strong></span>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[6].Quotes[0].Quote}}</th>
                            <th>{{ lan.admin[6].Quotes[0].Customer }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Date }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Account }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Status }}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(data, index) in displayed_quotesNew"
                          :key="index"
                          @click="edit(data.quote_id)"
                          class="pointer"
                        >
                          <tr
                            v-if="
                              data.status == 'New' || data.status == 'Saved'
                            "
                          >
                          <td>{{ data.quote_id }}</td>
                            <td
                              v-if="
                                data.customer_account_detail &&
                                data.customer_account_detail.length
                              "
                            >
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>{{ data.customer_account_detail[0].name }}</td> -->
                            <td>{{ data.date }}</td>
                            <td
                              :style="
                                data.account_detail.is_outside_partner == 1 || data.account_detail.is_outside_partner == '1'
                                  ? `background-color: #e4ecff`
                                  : ''
                              "
                            >
                              {{ data.account_detail.venue_name ? data.account_detail.venue_name : data.account_detail.name }}
                            </td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.status == 'New'
                                    ? 'badge-success'
                                    : 'badge-warning'
                                "
                                >{{ data.status == 'New' ? lan.Status.New : lan.Status.Saved }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="quotesNew.length > 5" class="text-right">
                        <button @click="toggleViewAll_quotesNew" class="view-all-less-btn">{{ showAll_quotesNew ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[6].Quotes[0].Waiting_for_acceptance }}
                  </h4>
                  <span>Total: <strong>( {{ quotesSubmitted.length }} )</strong></span>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[6].Quotes[0].Quote}}</th>
                            <th>{{ lan.admin[6].Quotes[0].Customer }}</th>
                            <th>
                              {{ lan.partners[1].quote[0].Preliminary_booked }}
                            </th>
                            <th>{{ lan.admin[6].Quotes[0].Date }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Account }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Status }}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(data, index) in displayed_quotesSubmitted"
                          :key="index"
                        >
                          <tr
                            v-if="
                              data.status == 'Submitted' ||
                              data.status == 'Preliminary Booked' ||
                              data.status == 'Preliminary Booking' ||
                              data.status == 'Preliminary Cancelled'
                            "
                          >
                          <td class="pointer">{{ data.quote_id }}</td>
                            <td
                              v-if="
                                data.customer_account_detail &&
                                data.customer_account_detail.length
                              "
                              class="pointer" @click="edit(data.quote_id)"
                            >
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>{{ data.customer_account_detail[0].name }}</td> -->
                            <td class="pointer" @click="edit(data.quote_id)">
                              {{
                                data.status == "Preliminary Booked"
                                  ? "Yes"
                                  : "No"
                              }}
                            </td>
                            <td class="pointer" @click="edit(data.quote_id)">{{ data.date }}</td>
                            <td class="pointer" @click="edit(data.quote_id)" :style="
                                data.account_detail.is_outside_partner == 1 || data.account_detail.is_outside_partner == '1'
                                  ? `background-color: #e4ecff`
                                  : ''
                              ">{{ data.account_detail.venue_name ? data.account_detail.venue_name : data.account_detail.name}}</td>
                            <td class="pointer" @click="(data.status == 'Preliminary Booking' || data.status == 'Preliminary Booked') ? openPreliminaryBooking(data) : edit(data.quote_id)">
                              <span
                                class="badge light"
                                :class="
                                  data.status == 'Submitted'
                                    ? 'badge-info'
                                    : data.status == 'Preliminary Booked'
                                    ? 'badge-success'
                                    : data.status == 'Preliminary Booking'
                                    ? 'badge-warning'
                                    : 'badge-danger'
                                "
                                >{{ data.status == 'Submitted'
                                    ? lan.Status.Submitted
                                    : data.status == 'Preliminary Booked'
                                    ? lan.Status.Preliminary_Booked
                                    : data.status == 'Preliminary Booking'
                                    ? lan.Status.Preliminary_Booking
                                    : lan.Status.Preliminary_Cancelled }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>.
                      <div v-if="quotesSubmitted.length > 5" class="text-right">
                        <button @click="toggleViewAll_quotesSubmitted" class="view-all-less-btn">{{ showAll_quotesSubmitted ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[6].Quotes[0].Accepted_quotes }}
                  </h4>
                  <span>Total: <strong>( {{ quotesAccepted.length }} )</strong></span>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[6].Quotes[0].Quote }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Customer }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Date }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Account }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Status }}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(data, index) in displayed_quotesAccepted"
                          :key="index"
                          @click="edit(data.quote_id)"
                          class="pointer"
                        >
                          <tr v-if="data.status == 'Accepted'">
                            <td>{{ data.quote_id }}</td>
                            <td
                              v-if="
                                data.customer_account_detail &&
                                data.customer_account_detail.length
                              "
                            >
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>{{ data.customer_account_detail[0].name }}</td> -->
                            <td>{{ data.date }}</td>
                            <td :style="
                                data.account_detail.is_outside_partner == 1 || data.account_detail.is_outside_partner == '1'
                                  ? `background-color: #e4ecff`
                                  : ''
                              ">{{ data.account_detail.venue_name ? data.account_detail.venue_name : data.account_detail.name}}</td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.status == 'Accepted'
                                    ? 'badge-success'
                                    : 'badge-warning'
                                "
                                >{{ data.status == 'Accepted' ? lan.Status.Accepted : data.status }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="quotesAccepted.length > 5" class="text-right">
                        <button @click="toggleViewAll_quotesAccepted" class="view-all-less-btn">{{ showAll_quotesAccepted ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[6].Quotes[0].Accepted_Denied }}
                  </h4>
                  <span>Total: <strong>( {{ quotesDenied.length }} )</strong></span>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[6].Quotes[0].Quote }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Customer }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Date }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Account }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Status }}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(data, index) in displayed_quotesDenied"
                          :key="index"
                          @click="edit(data.quote_id)"
                          class="pointer"
                        >
                          <tr v-if="data.status == 'Denied'">
                            <td>{{ data.quote_id }}</td>
                            <td
                              v-if="
                                data.customer_account_detail &&
                                data.customer_account_detail.length
                              "
                            >
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>{{ data.customer_account_detail[0].name }}</td> -->
                            <td>{{ data.date }}</td>
                            <td>{{ data.account_detail.venue_name ? data.account_detail.venue_name : data.account_detail.name}}</td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.status == 'Denied'
                                    ? 'badge-danger'
                                    : 'badge-success'
                                "
                                >{{ data.status == 'Denied' ? lan.Status.Denied : data.status }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="quotesDenied.length > 5" class="text-right">
                        <button @click="toggleViewAll_quotesDenied" class="view-all-less-btn">{{ showAll_quotesDenied ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[6].Quotes[0].Quote_lost }}
                  </h4>
                  <span>Total: <strong>( {{ quotesLost.length }} )</strong></span>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[6].Quotes[0].Quote }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Customer }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Date }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Account }}</th>
                            <th>{{ lan.admin[6].Quotes[0].Status }}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(data, index) in displayed_quotesLost"
                          :key="index"
                          @click="edit(data.quote_id)"
                          class="pointer"
                        >
                          <tr v-if="data.status == 'Lost'">
                            <td>{{ data.quote_id }}</td>
                            <td
                              v-if="
                                data.customer_account_detail &&
                                data.customer_account_detail.length
                              "
                            >
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>{{ data.customer_account_detail[0].name }}</td> -->
                            <td>{{ data.date }}</td>
                            <td>{{ data.account_detail.venue_name ? data.account_detail.venue_name : data.account_detail.name}}</td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.status == 'Lost'
                                    ? 'badge-danger'
                                    : 'badge-success'
                                "
                                >{{ data.status == 'Lost' ? lan.Status.Lost : data.status }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="quotesLost.length > 5" class="text-right">
                        <button @click="toggleViewAll_quotesDenied" class="view-all-less-btn">{{ showAll_quotesLost ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Quotes",
  data() {
    return {
      quotesDetails: "",
      quotesNew: [],
      quotesSubmitted: [],
      quotesAccepted: [],
      quotesDenied: [],
      quotesLost: [],
      showAll_quotesNew: false,
      showAll_quotesSubmitted: false,
      showAll_quotesAccepted: false,
      showAll_quotesDenied: false,
      showAll_quotesLost: false,
      value: true,
    };
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
    displayed_quotesNew() {
      return this.showAll_quotesNew ? this.quotesNew : this.quotesNew.slice(0, 5);
    },
    displayed_quotesSubmitted() {
      return this.showAll_quotesSubmitted ? this.quotesSubmitted : this.quotesSubmitted.slice(0, 5);
    },
    displayed_quotesAccepted() {
      return this.showAll_quotesAccepted ? this.quotesAccepted : this.quotesAccepted.slice(0, 5);
    },
    displayed_quotesDenied() {
      return this.showAll_quotesDenied ? this.quotesDenied : this.quotesDenied.slice(0, 5);
    },
    displayed_quotesLost() {
      return this.showAll_quotesLost ? this.quotesLost : this.quotesLost.slice(0, 5);
    },
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    // // *********GET quotes***********
    axios
      .get(axios.defaults.baseURL + "quotes", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.value = false;
        this.quotesDetails = res.data.data;
        // this.quotesNew = res.data.data.filter((item) => item.status == "New");
        res.data.data.forEach(element => {
          if(element.status == "New" || element.status == "Saved")this.quotesNew.push(element)
          else if(element.status == "Submitted" || element.status == "Preliminary Booked" || element.status == "Preliminary Booking" || element.status == "Preliminary Cancelled")this.quotesSubmitted.push(element)
          else if(element.status == "Accepted") this.quotesAccepted.push(element)
          else if(element.status == "Denied") this.quotesDenied.push(element)
          else if(element.status == "Lost") this.quotesLost.push(element)
        });
      })
      .catch((err) => {
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    toggleViewAll_quotesNew() {
      this.showAll_quotesNew = !this.showAll_quotesNew;
    },
    toggleViewAll_quotesSubmitted() {
      this.showAll_quotesSubmitted = !this.showAll_quotesSubmitted;
    },
    toggleViewAll_quotesAccepted() {
      this.showAll_quotesAccepted = !this.showAll_quotesAccepted;
    },
    toggleViewAll_quotesDenied() {
      this.showAll_quotesDenied = !this.showAll_quotesDenied;
    },
    toggleViewAll_quotesDenied() {
      this.showAll_quotesLost = !this.showAll_quotesLost;
    },
    openPreliminaryBooking(data){
      console.log('data', data);
      console.log('bidderID', data._id);
      this.$router.push({
        name: "Preliminary Booking",
        params: { 
          id: data.opportunity_id, 
          qID: data.quote_id,
          cutomerID: data.customer_account_id,
          bidderID: data.bidder_id,
          bookingID: data.preliminary_booking_id,
        },
      });
    },
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({
        name: "Edit Quote",
        params: { id: id },
      });
    },
  },
};
</script>
